.Donate p, .Donate ul {
    font-size: 20px;
}

.Donate a {
    box-shadow: inset 0 -0.07em 0 var(--primary);
    font-weight: bold;
    color: inherit;
    transition: box-shadow 270ms cubic-bezier(0.77, 0, 0.175, 1), color 270ms cubic-bezier(0.77, 0, 0.175, 1);
}

.Donate a:hover {
    box-shadow: inset 0 -0.85em 0 var(--primaryTransparent);
}

.Donate h3 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 24px;
}

.data {
    display: flex;
    justify-content: space-between;
}

.data>div {
    width: calc(50% - 24px);
}

.balance {
    font-size: 48px;
    font-weight: 700;
    color: #0cc112;
}

.updated {
    color: var(--textSecondary);
    margin-right: 10px;
}

.updated a {
    box-shadow: none !important;
    font-weight: 400;
    text-decoration: underline;
}

.updated a:hover {
    text-decoration: none;
}

.widget {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.widget>div {
    width: calc(50% - 32px);
}

.paymentType {
    display: flex;
    align-items: center;
}

.option {
    display: flex;
    align-items: center;
    padding: 8px;
    cursor: pointer;
}

.option:focus {
    outline: none;
}

.optionDot {
    height: 24px;
    width: 24px;
    border: 1px var(--border) solid;
    border-radius: 50%;
    margin-right: 8px;
    transition: border-color .2s ease;
}

.option:hover .optionDot, .option:focus .optionDot {
    border-color: var(--primary);
}

.optionDot::after {
    content: "";
    display: block;
    background-color: var(--dotColor);
    height: 12px;
    width: 12px;
    margin: 5px;
    opacity: 0;
    border-radius: 50%;
    transform: scale(.6);
    transition: opacity .2s ease-out, transform .2s ease;
}

.optionActive .optionDot::after {
    opacity: 1;
    transform: none;
}

.paymentAmount {
    display: flex;
    flex-wrap: wrap;
}

.paymentVariant {
    padding: 8px 12px;
    border: 1px var(--border) solid;
    cursor: pointer;
    margin: 8px;
    transition: border-color .2s ease, color .2s ease, background-color .2s ease, transform .2s ease;
}

.paymentVariant:active {
    transform: scale(.98);
}

.paymentVariant:hover, .paymentVariant:focus {
    border-color: var(--fundColor);
    outline: none;
}

.paymentActive {
    border-color: var(--primary);
    background-color: var(--primary);
    color: #fff;
}

.paymentInput input, .emailInput input {
    padding: 8px 12px;
    border: 1px var(--border) solid;
    margin: 8px;
    width: 100%;
    -webkit-border-radius: 0;
    border-radius: 0;
    transition: border-color .2s ease, color .2s ease, background-color .2s ease;
}

.paymentInput input:focus, .emailInput input:focus {
    border-color: var(--focusColor);
    outline: none!important;
}

.paymentButton {
    border: none;
    display: block;
    cursor: pointer;
    outline: none!important;
    margin: 8px;
    appearance: none;
    width: 100%;
    text-align: center;
    color: #fff;
    background-color: var(--fundColor);
    padding: 12px 24px;
    text-transform: uppercase;
    text-decoration: none;
    box-shadow: 0 4px 16px -4px var(--border);
    transition: transform .3s ease-out, opacity .3s ease-out, background-color .3s ease;
}

.paymentButton:hover {
    opacity: .9;
}

.paymentButton:active {
    transform: scale(0.98);
}

.paymentButton:disabled {
    background-color: var(--textLow);
    cursor: not-allowed;
    box-shadow: none;
}

.agree {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 8px 16px 8px;
}

.check {
    position: relative;
    width: 32px;
    height: 32px;
    border: 1px solid var(--border-donate);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .check:focus {
    border-color: var(--focusColor);
    outline: none;
  }
  .agreeActive .check {
    border-color: var(--focusColor);
  }
  .checkmark {
    width: 24px;
    height: 24px;
    fill: none;
    stroke: var(--focusColor);
    stroke-width: 1.5;
    stroke-linecap: round;
    stroke-linejoin: round;
    opacity: 0;
    transition: opacity 0.2s ease;
  }
  
  .agreeActive .checkmark {
    opacity: 1;
  }
.agree span {
    width: calc(100% - 48px);
}

.info {
    margin: 8px;
    margin-top: 16px;
    font-size: 14px;
    color: var(--textLow);
}

.info a {
    box-shadow: none!important;
}

.additional {
    display: flex;
    align-items: center;
    color: var(--textLow);
}

.separator {
    margin: 0 8px;
    background-color: var(--textLow);
    height: 24px;
    width: 1px;
}

.additional svg {
    width: 32px;
    margin: 8px;
    fill: currentColor
}

.text p, .text ul {
    font-size: 16px;
}


.fundtitle {
    font-size: 24px;
    font-weight: 700;
}

.fundGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;

}

.balEndowment {
    font-size: 48px;
    font-weight: 700;
    color: #03045E;
}

.balDev {
    font-size: 48px;
    font-weight: 700;
    color: #023E8A;
}

.balSalary {
    font-size: 48px;
    font-weight: 700;
    color: #0077B6;
}

.balSponsor {
    font-size: 48px;
    font-weight: 700;
    color: #0087BF;
}

.balTitle p {
    font-weight: 400;
    font-size: 16px;
    margin-top: 16px;
}
.balTitle a {
    font-weight: 400;
}
.endowInvest {
    font-size: 48px;
    font-weight: 700;
    color: #0096C7;
}

.endowCur {
    font-size: 48px;
    font-weight: 700;
    color: #00B4D8;
}

.balSalaryRob {
    font-size: 48px;
    font-weight: 700;
    color: #48CAE4;
}

.balDevRob {
    font-size: 48px;
    font-weight: 700;
    color: #90E0EF;
}


@media (max-width: 768px) {
    .widget {
        flex-direction: column;
    }
    .widget>div {
        width: 100%;
    }
    .data {
        flex-direction: column;
    }
    .data>div {
        width: 100%;
    }
    .Donate p, .Donate ul {
        font-size: 16px;
    }
    .Donate h3 {
        font-size: 20px;
    }
    .fundGrid {
        grid-template-columns: 1fr;
    }
    .updated {
        display: block;
    }
    .hidden {
        display: none;
    }
}