.warning {
    display: none;
}

@media (max-width: 768px) {
    .graph {
        display: none;
    }
    .warning {
        display: block;
        font-style: italic;
    }
}