.Post {
    padding-bottom: 32px;
}

.header {
    position: relative;
    min-height: 480px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 32px;
    margin: 32px 0;
    border-radius: 24px;
    overflow: hidden;
    box-shadow: 0 16px 24px -8px var(--backdrop);
    background-size: cover;
    background-position: center;
}

.header::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    height: 100%;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, .08), rgba(0, 0, 0, .64));
}

.header h1 {
    color: #fff;
    position: relative;
    z-index: 1;
    margin: 0;
}

.content p, .content ul {
    max-width: 720px;
    margin: 24px auto;
    font-size: 18px;
}

.content a {
    box-shadow: inset 0 -0.07em 0 var(--primary);
    font-weight: bold;
    color: inherit;
    transition: box-shadow 270ms cubic-bezier(0.77, 0, 0.175, 1), color 270ms cubic-bezier(0.77, 0, 0.175, 1);
}

.content a:hover {
    box-shadow: inset 0 -0.85em 0 var(--primaryTransparent);
}

.button {
    border: none;
    display: block;
    cursor: pointer;
    outline: none!important;
    margin: 32px auto;
    appearance: none;
    width: fit-content;
    text-align: center;
    color: #fff;
    background-color: var(--primary);
    padding: 12px 24px;
    text-transform: uppercase;
    font-size: 14px;
    text-decoration: none;
    box-shadow: 0 4px 16px -4px var(--border);
    transition: transform .3s ease-out, opacity .3s ease-out;
}

.button:hover {
    opacity: .9;
}

.button:active {
    transform: scale(0.98);
}

@media (max-width: 960px) {
    .header {
        min-height: 360px;
        padding: 24px;
    }
}

@media (max-width: 768px) {
    .header {
        margin: 0;
        border-radius: 16px;
        min-height: 320px;
    }
    .content p, .content ul {
        font-size: 16px;
    }
}