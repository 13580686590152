.Loader {
    width: 100%;
    min-height: 240px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.element {
    stroke-dasharray: 122 60;
    stroke-dashoffset: 182;
    animation: EagfyoDq_draw 1s linear infinite forwards;
}

@keyframes EagfyoDq_draw {
    100% {
        stroke-dashoffset: 0;
    }
}