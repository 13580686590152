.Logo {
    display: flex;
    align-items: center;
    overflow: hidden;
    transition: --size .3s ease-out;
    height: var(--size);
    transition: height .3s ease-out;
}

.Logo svg {
    display: block;
    margin-right: calc(var(--size) * 0.25);
    height: var(--size);
    width: var(--size);
    transition: all .3s ease-out;
}

.title {
    font-family: "Manrope", "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: calc(var(--size) * .5);
    line-height: 0.8125;
    transition: font-size .3s ease-out;
    text-align: left;
}

.title span {
    display: block;
}

.title.animated span {
    animation: titleAnimated var(--duration1) cubic-bezier(0.19, 1, 0.22, 1) var(--duration3) both;
}

.title.animated span:last-of-type {
    animation-delay: var(--duration1);
}

.bEjIzBBZ_1 {
    stroke-dasharray: 182 184;
    stroke-dashoffset: 183;
    animation: bEjIzBBZ_draw var(--duration2) ease-out var(--duration3) forwards;
}

.bEjIzBBZ_2 {
    animation: bEjIzBBZ_fade var(--duration1) ease-out var(--duration4) both;
}

@keyframes bEjIzBBZ_draw {
    100% {
        stroke-dashoffset: 0;
    }
}

@keyframes bEjIzBBZ_fade {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes titleAnimated {
    from {
        opacity: 0;
        transform: translateY(100%);
    }
    to {
        opacity: 1;
        transform: none;
    }
}