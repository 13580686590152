.Navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    padding-top: 64px;
    background-color: var(--back);
    font-family: "Manrope", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    transition: box-shadow .3s ease-out, padding .3s ease-out;
}

:global(.kz) .Navbar {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.Navbar.scrolled {
    padding-top: 32px;
    padding-bottom: 32px;
    box-shadow: 0 8px 16px -4px var(--backdrop);
}

.Container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lang {
    position: relative;
    width: 28px;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
    animation: fadeIn 1.6s 1s cubic-bezier(0.19, 1, 0.22, 1) both;
}

.subscribe{
    cursor: pointer;
    animation: fadeIn 1.6s 1s cubic-bezier(0.19, 1, 0.22, 1) both;
}

.lang svg {
    margin: 0 2px;
}

.lang svg:hover {
    color: var(--textSecondary);
}

.subscribe svg:hover{
    color: var(--textSecondary);
}

.langMenu {
    position: absolute;
    z-index: 100;
    left: 50%;
    transform: translateX(-50%) translateY(24px);
    padding: 12px 0;
    top: calc(100% + 12px);
    border-radius: 6px;
    background-color: var(--back);
    opacity: 0;
    transition: transform .4s cubic-bezier(0.19, 1, 0.22, 1), opacity .4s cubic-bezier(0.19, 1, 0.22, 1);
    box-shadow: 0 16px 24px -8px var(--backdrop), 0 0 0 1px var(--backdrop);
}

.langMenu.langMenuActive {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
}

.langMenu::after {
    content: "";
    display: block;
    position: absolute;
    top: -6px;
    left: calc(50% - 6px);
    border-top-left-radius: 3px;
    height: 12px;
    width: 12px;
    box-shadow: -1px -1px 0 0 var(--backdrop);
    transform: rotate(45deg);
    background-color: var(--back);
}

.langMenuItem {
    padding: 1px 12px;
    color: var(--text);
    transition: background-color .13s ease, color .13s ease;
}

.langMenuItem:hover {
    color: var(--primary);
}

.langMenuItemSelected {
    background-color: var(--primary);
    color: var(--back)!important;
}

.burgerWrap {
    width: 28px;
    height: 18px;
}

.burger {
    position: fixed;
    z-index: 101;
    cursor: pointer;
    animation: fadeIn 1.6s 1s cubic-bezier(0.19, 1, 0.22, 1) both;
}

.burger div {
    width: 28px;
    height: 2px;
    background-color: var(--text);
    transition: opacity .2s ease-out, transform .2s ease-out;
}

.burger div:not(:last-of-type) {
    margin-bottom: 6px;
}

.open .burger div:first-of-type {
    transform: translateY(8px) rotate(45deg);
}

.open .burger div:nth-of-type(2) {
    opacity: 0;
    transform: scale(0);
}

.open .burger div:last-of-type {
    transform: translateY(-8px) rotate(-45deg);
}

.menuWrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    z-index: 100;
    pointer-events: none;
    opacity: 0;
    overflow: hidden;
    transition: opacity 830ms cubic-bezier(0.19, 1, 0.22, 1);
}

.open .menuWrapper {
    pointer-events: all;
    opacity: 1;
}

.Logo {
    margin-bottom: 32px;
    height: 64px!important;
}

.scrolled .Logo {
    height: 40px!important;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    background-color: var(--backdrop);
}

.menu {
    padding-top: 64px;
    padding-bottom: 24px;
    position: fixed;
    width: 320px;
    right: 0;
    top: 0;
    height: 100%;
    z-index: 1;
    background-color: var(--back);
    transform: translateX(24px);
    transition: transform 830ms cubic-bezier(0.19, 1, 0.22, 1);
    box-shadow: 8px 0 16px -4px var(--backdrop);
}

.scrolled .menu {
    padding-top: 32px;
}

.menuInner {
    position: relative;
    max-width: 320px;
    margin-right: auto;
    padding: 0 32px;
    height: 100%;
}

.menuItems {
    position: relative;
    height: calc(100% - 140px);
}

.menuItemsInner {
    overflow-y: auto;
    padding: 8px 0;
    height: 100%;
    -webkit-overflow-scrolling: touch;
}

.menuItems::before, .menuItems::after {
    pointer-events: none;
    position: absolute;
    left: 0;
    content: "";
    display: block;
    height: 32px;
    width: 100%;
    z-index: 10;
}

.menuItems::before {
    top: 0;
    background: linear-gradient(to bottom, var(--back), var(--backTransparent));
}

.menuItems::after {
    bottom: 0;
    background: linear-gradient(to top, var(--back), var(--backTransparent));
}

.scrolled .menuItems {
    height: calc(100% - 116px);
}

.menuItemText{
    display: block;
    font-size: 14px;
    font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 400;
    color: var(--textSecondary);
}

.socialLinks {
    display: flex;
    margin-top: 12px;
}

.socialLinks a {
    color: inherit;
    text-decoration: none;
}

.socialLinks svg {
    display: block;
    height: 24px;
    width: 24px;
    fill: var(--text);
    transition: fill .2s ease-out;
}

.socialLinks a:not(:last-of-type) {
    margin-right: 16px;
}

.socialLinks a:hover svg {
    fill: var(--textSecondary);
}

.socialLinks a:active svg {
    fill: var(--primary);
}

.open .menu {
    transform: none;
}

.menu .burger {
    position: absolute;
    top: 23px;
    right: 24px;
}

.scrolled .menu .burger {
    top: 11px;
}

.menuItem {
    position: relative;
    display: block;
    padding: 8px 6px;
    color: inherit;
    text-decoration: none;
    transition: color .2s ease-out;
}

.AccordionTitle {
    padding: 8px 6px;
    display: flex;
    color: inherit;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    cursor: pointer;
    transition: color .2s ease-out;
    border-bottom: 1px var(--border) solid;
}

.AccordionTitle:hover {
    color: var(--textSecondary);
}

.AccordionContent {
    padding-left: 8px;
    border-bottom: 1px var(--border) solid;
}

.menuItem:not(:last-of-type) {
    border-bottom: 1px var(--border) solid;
}

.menuItem span {
    display: block;
    width: calc(100% - 16px);
    transition: transform .6s cubic-bezier(0.19, 1, 0.22, 1);
}

.menuItem:hover {
    color: var(--textSecondary)
}

.menuItemActive {
    color: var(--primary)!important;
}

.menuItemActive span {
    transform: translateX(16px);
}

.menuItem::before {
    pointer-events: none;
    position: absolute;
    display: block;
    content: "";
    height: 8px;
    width: 8px;
    border-radius: 4px;
    background-color: var(--primary);
    top: calc(50% - 4px);
    opacity: 0;
    transition: opacity .6s cubic-bezier(0.19, 1, 0.22, 1), transform .6s cubic-bezier(0.19, 1, 0.22, 1);
    transform: translateX(-16px);
}

.menuItemActive::before {
    opacity: 1;
    transform: none;
}

.external {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right center;
}

.actions{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@media (max-width: 768px) {
    .Logo {
        height: 40px!important;
    }
    .Navbar {
        padding-top: 32px;
        padding-bottom: 32px;
    }
    .menu .burger {
        top: 11px;
    }
    .menu {
        padding-top: 32px;
        padding-bottom: 24px;
    }
    .menuInner {
        padding: 0 24px;
    }
    .menuItems {
        height: calc(100% - 116px);
    }
}