.Lampa p, .Lampa ul {
    font-size: 20px;
}

.Lampa a {
    box-shadow: inset 0 -0.07em 0 var(--primary);
    font-weight: bold;
    color: inherit;
    transition: box-shadow 270ms cubic-bezier(0.77, 0, 0.175, 1), color 270ms cubic-bezier(0.77, 0, 0.175, 1);
}

.Lampa a:hover {
    box-shadow: inset 0 -0.85em 0 var(--primaryTransparent);
}

.Lampa h3 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 24px;
}

.members {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    padding-top: 24px;
    margin: 0 -16px;
}

.members>div {
    width: calc(50% - 32px);
    margin: 16px;
}

.membersSmall>div {
    width: calc(33.333% - 32px);
}

.Member {
    text-align: center;
}

.memberPhoto {
    height: 128px;
    width: 128px;
    background-color: var(--border);
    margin: auto;
    border-radius: 50%;
    cursor: pointer;
    background-position: center;
    background-size: cover;
}

.Member h3 {
    font-size: 24px;
    margin: 16px 0 8px 0;
}

.Member p {
    font-size: 14px;
}

.memberActions {
    display: flex;
    justify-content: center;
}

.info, .memberEmail {
    width: fit-content;
    display: block;
    appearance: none;
    background-color: transparent;
    color: var(--textSecondary);
    padding: 8px;
    border: none;
    outline: none!important;
    cursor: pointer;
    transition: color .3s ease-out;
}

.info:hover, .memberEmail:hover {
    color: var(--primary);
}

.info:active, .memberEmail:active {
    color: var(--text)
}

@media (max-width: 768px) {
    .Lampa p, .Lampa ul {
        font-size: 16px;
    }
    .Lampa h3 {
        font-size: 20px;
    }
    .members>div {
        width: calc(100% - 32px);
    }
    .Member h3 {
        font-size: 20px;
        line-height: 1.2;
        margin-bottom: 4px;
    }
}