
.paymentButton {
    border: none;
    display: block;
    cursor: pointer;
    outline: none!important;
    /* margin: 8px; */
    appearance: none;
    width: 100%;
    text-align: center;
    color: #fff;
    background-color: var(--primary);
    padding: 12px 24px;
    text-transform: uppercase;
    text-decoration: none;
    box-shadow: 0 4px 16px -4px var(--border);
    transition: transform .3s ease-out, opacity .3s ease-out, background-color .3s ease;
}

.paymentButton:hover {
    opacity: .9;
}

.paymentButton:active {
    transform: scale(0.98);
}

.paymentButton:disabled {
    background-color: var(--textLow);
    cursor: not-allowed;
    box-shadow: none;
}

.paymentInput input, .emailInput input {
    padding: 8px 12px;
    border: 1px var(--border) solid;
    margin: 8px 0 12px 0;
    width: 100%;
    -webkit-border-radius: 0;
    border-radius: 0;
    transition: border-color .2s ease, color .2s ease, background-color .2s ease;
}

.paymentInput input:focus, .emailInput input:focus {
    border-color: var(--primary);
    outline: none!important;
}


.itemCover {
    width: 100%;
}

.item {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.itemDetails {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 60px;
}


.title {
    font-weight: 700;
    width: 100%;
}

.link {
    box-shadow: inset 0 -0.07em 0 var(--primary);
    font-weight: bold;
    color: inherit;
    transition: box-shadow 270ms cubic-bezier(0.77, 0, 0.175, 1), color 270ms cubic-bezier(0.77, 0, 0.175, 1);
}

.link:hover {
    box-shadow: inset 0 -0.85em 0 var(--primaryTransparent);
}

.container {
    width: 50%;
}