.Projects {
    padding: 96px 0;
}

.Projects h2 {
    font-size: 32px;
    margin: 0 0 32px 0;
}

.items {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 48px;
    margin: auto;
}

.item {
    display: flex;
    padding: 16px 24px 4px 24px;
    border-radius: 5px;
    width: 100%;
    align-items: center;
    box-shadow: 0 5px 10px rgba(0,0,0,0.12);    ;
    transition: box-shadow 270ms ease, color 270ms cubic-bezier(0.77, 0, 0.175, 1);
}

.item:nth-child(even) {
    transform: translateY(20%);
}

.item:hover {
    box-shadow: 0 8px 30px rgba(0,0,0,0.12);
}


.itemInfo {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M18 14v5a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8c0-1.1.9-2 2-2h5M15 3h6v6M10 14L20.2 3.8'/%3E%3C/g%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right top;
}

.itemInfoFirst {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.itemInfoFirst h3 {
    margin: 0 16px 0 0;
    font-weight: 800;
    font-size: 32px;
    line-height: 1;
}

.itemActive {
    display: inline-flex;
    border-radius: 4px;
    padding: 4px 8px;
    align-items: center;
    border: 2px solid var(--green);
    color: var(--green);
    font-size: 14px;
}

.itemNull {
    display: inline-flex;
    border-radius: 4px;
    padding: 4px 8px;
    align-items: center;
    border: 2px solid var(--secondary);
    color: var(--secondary);
    font-size: 14px;
}

.itemFrozen {
    display: inline-flex;
    border-radius: 4px;
    padding: 4px 8px;
    align-items: center;
    border: 2px solid var(--primary);
    color: var(--primary);
    font-size: 14px;
}


.itemInfoSecond {
    color: var(--textSecondary);
    padding-top: 8px;
    line-height: 1.25;
}

.dateInfo {
    font-weight: 800;
}

@media (max-width: 1000px) {
    .items {
        display: grid;
        grid-template-columns: 1fr;
        gap: 24px;
        margin: auto; 
        width: 70%
    }
    .item:nth-child(even) {
        transform: none;
    }
    .itemActive, .itemFrozen, .itemNull {
        font-size: 12px;
    }
    .Projects {
        padding-bottom: 24px;
    }
}

@media (max-width: 700px) {
    .Projects h2 {
        font-size: 24px;
    }
    .items {
        width: 90%
    }
    .itemActive, .itemFrozen, .itemNull {
        font-size: 10px;
    }
    
    .itemInfoFirst h3 {
        font-size: 24px;
    }
    .itemInfoSecond {
        font-size: 14px;
        line-height: 1.1;
    }
}

@media (max-width: 420px) {
    .itemIcon {
        width: 72px;
        margin-right: 8px;
    }
    .itemInfoFirst {
        margin: 0;
    }
    .itemInfoFirst h3 {
        font-size: 20px;
        margin-right: 4px;
    }
    .itemInfoSecond {
        font-size: 13px;
        line-height: 1.1;
    }
}