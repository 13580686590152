.About p, .About ul, .timeText {
    font-size: 20px;
}

.About a {
    box-shadow: inset 0 -0.07em 0 var(--primary);
    font-weight: bold;
    color: inherit;
    transition: box-shadow 270ms cubic-bezier(0.77, 0, 0.175, 1), color 270ms cubic-bezier(0.77, 0, 0.175, 1);
}

.About a:hover {
    box-shadow: inset 0 -0.85em 0 var(--primaryTransparent);
}

.About h3 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 24px;
}

.timeline {
    position: relative;
    border-left: 1px solid var(--border);
    padding-left: 16px;
    max-width: 600px;
}

.timeBlock {
    position: relative;
    padding: 0 32px;
    margin: auto auto 64px;
    max-width: 600px;
}

.timeDate {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 8px;
}

.timeBlock::before {
    position: absolute;
    top: calc(50% - 4.5px);
    border-radius: 50%;
    left: -21px;
    content: "";
    display: block;
    height: 9px;
    width: 9px;
    background-color: var(--primary);
}

.Slider {
    width: 100vw;
    overflow: hidden;
}

.SliderInner {
    width: fit-content;
    white-space: nowrap;
    animation: scroll 32s linear infinite;
}

.values {
    display: inline-block;
    white-space: nowrap;
    padding: 8px 12px;
    margin: 16px;
    border-radius: 5px;
    box-shadow: 0 8px 16px -4px var(--border);
}

.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex p {
    width: calc(70% - 24px);
}

.About a.ustav {
    display: block;
    position: relative;
    width: calc(30% - 24px);
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 8px 16px -4px var(--border);
}

.About a.ustav:hover {
    box-shadow: 0 16px 32px -4px var(--border);
}

.label {
    position: absolute;
    width: 128px;
    line-height: 32px;
    text-align: center;
    background-color: var(--primary);
    color: #fff;
    text-transform: uppercase;
    transform: rotate(-45deg) translate(-32px, -12px);
}

a.ustav img {
    display: block;
    max-width: 100%;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-33.333%);
    }
}

@media (max-width: 768px) {
    .About p, .About ul, .timeText {
        font-size: 16px;
    }
    .About h3 {
        font-size: 20px;
    }
    .timeDate {
        font-size: 20px;
    }
    .flex {
        flex-direction: column;
        align-items: center;
    }
    .flex p {
        width: 100%;
    }
    .About a.ustav {
        max-width: 300px;
        width: 100%;
    }
}