table {
  border-spacing: 0;
  display: block;
  overflow-x: auto;
  text-align: left;
  width: 100%;
}

td,
th {
  border-bottom: .1rem solid var(--border);
  padding: 1.2rem 1.5rem;
}
td:first-child, th:first-child {
    padding-left: 0;
}

td:last-child, th:last-child {
    padding-right: 0;
}

.link {
  text-decoration: underline;
}
@media (min-width: 40.0rem) {
  table {
    display: table;
    overflow-x: initial;
    }
}