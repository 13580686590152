.grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 32px;
    padding-top: 48px;
}

h3 {
    font-size: 32px;
    margin-top: 32px;
}

@media (max-width: 768px) {
    h3 {
        font-size: 24px;
    }
    p {
        font-size: 14px;
        text-align: left;
    }
}

.card{
    border-radius: 7px;
    border: 1px var(--border) solid;
    overflow: hidden;
    text-align: left;
    cursor: pointer;
    transform: none;
    transition: box-shadow .2s ease, transform .2s ease!important;
}

.card:hover{
    transform: scale(1.04);
    box-shadow: 0 16px 24px -4px rgba(0,0,0,.14)!important;
}

.cardCover{
    height: 96px;
    background-color: var(--primary);
    background-size: contain;
    background-position: left center;
    background-repeat: no-repeat;
}

.cardContent{
    padding: 12px;
    font-size: 18px;
    font-weight: 600;
}

@media (max-width: 900px){
    .grid{
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 578px){
    .grid{
        grid-template-columns: 1fr;
    }
}