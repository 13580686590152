.field {
    margin-bottom: 12px;
}

.double {
    display: flex;
    justify-content: space-between;
}

.double>div {
    width: calc(50% - 24px);
}

.Contacts p, .Contacts ul {
    font-size: 20px;
}

.Contacts a {
    box-shadow: inset 0 -0.07em 0 var(--primary);
    font-weight: bold;
    color: inherit;
    transition: box-shadow 270ms cubic-bezier(0.77, 0, 0.175, 1), color 270ms cubic-bezier(0.77, 0, 0.175, 1);
}

.Contacts a:hover {
    box-shadow: inset 0 -0.85em 0 var(--primaryTransparent);
}

.Contacts h3 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 24px;
}

.Contacts form button {
    border: none;
    cursor: pointer;
    outline: none!important;
    margin-top: 16px;
    appearance: none;
    color: #fff;
    background-color: var(--primary);
    display: inline-block;
    padding: 12px 24px;
    text-transform: uppercase;
    font-size: 14px;
    text-decoration: none;
    box-shadow: 0 4px 16px -4px var(--border);
    transition: transform .3s ease-out, opacity .3s ease-out;
}

.Contacts form button:hover {
    opacity: .9;
}

.Contacts form button:active {
    transform: scale(0.98);
}

.text {
    padding-top: 48px;
}

.key{
    display: flex;
    margin: auto;
    margin-bottom: 24px;
    width: fit-content;
    box-shadow: none!important;
    padding: 12px;
    border-radius: 8px;
    border: 1px var(--border) solid;
    transform: none;
    transition: box-shadow .2s ease, transform .2s ease!important;
}

.key:hover{
    transform: scale(1.04);
    box-shadow: 0 16px 24px -4px rgba(0,0,0,.14)!important;
}

.keyText{
    text-align: left;
    margin-left: 12px;
}

.keyTitle{
    font-size: 20px;
    font-weight: 800;
}

.keySubtitle{
    font-size: 14px;
    font-weight: 500;
    color: var(--textSecondary)
}

.docs{
    display: flex;
    align-items: center;
    justify-content: center;
}

.docs p{
    width: calc(100% - 288px);
    margin-left: 96px;
    text-align: left;
    max-width: 500px;
}

.docs img{
    width: 192px;
}

@media (max-width: 768px) {
    .docs{
        flex-direction: column;
    }
    .docs p{
        max-width: 100%;
        width: 100%;
        margin: 32px 0 0 0 ;
        text-align: center;
    }
    .docs img{
        width: 96px;
    }
    .double {
        flex-direction: column;
    }
    .double>div {
        width: 100%;
    }
    .Contacts p, .Contacts ul {
        font-size: 16px;
    }
    .Contacts h3 {
        font-size: 20px;
    }
}