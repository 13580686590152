.container {
    margin: 72px 0 72px 0;
}

.link {
    margin: 8px 0 8px 0;
    color: var(--text);
    width: max-content;
    text-decoration: none;
    font-weight: 300;
    box-shadow: inset 0 -.07em 0 var(--primary);
    transition: box-shadow .27s cubic-bezier(.77,0,.175,1),color .27s cubic-bezier(.77,0,.175,1);
  }
  
.link:hover {
    border-color: var(--primary);
    box-shadow: inset 0 -0.85em 0 var(--primaryTransparent);
  }

.flexbox {
    display: flex;
    flex-direction: column;
}
  

@media (max-width: 768px) {
    .container {
        margin: 0 0 48px 0;
    }
    .link {
        width: 100%;
        margin: 4px 0 4px 0;
    }
    h1 {
        font-size: 1.5em;
    }
}