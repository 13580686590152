.FileTable{
    display: grid;
    gap: 32px;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.file{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.file:hover .fileIcon{
    background-color: var(--border);
}

.file svg{
    display: block;
}

.fileIcon{
    box-shadow: 0 0 0 1px rgba(0,0,0,.04), 0 8px 16px -4px rgba(0,0,0,.14);
    padding: 12px;
    border-radius: 7px;
    margin-bottom: 16px;
}


.fileMeta{
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
}

.fileName{
    line-height: 1.1;
    margin-bottom: 4px;
    font-size: 14px;
    text-align: center;
    width: 100%;
}

.fileUrl{
    line-height: 1.1;
    font-size: 12px;
    color: var(--textSecondary);
    text-align: center;
    width: 100%;
}

/*.fileName, .fileUrl{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
}*/

@media (max-width: 768px){
    .FileTable{
        gap: 24px;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 468px){
    .FileTable{
        grid-template-columns: 1fr 1fr;
    }
}