

.agree {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 8px 0 16px 0;
}

.agree .check {
    width: 32px;
    height: 32px;
    border: 1px solid var(--border-donate);
    cursor: pointer;
}

.agree .check:focus {
    border-color: var(--primary);
    outline: none;
}

.agreeActive .check {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%239c8762' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
    background-position: center;
    background-repeat: none;
    border-color: var(--primary);
}

.agree span {
    width: calc(100% - 48px);
}

.paymentInput input, .emailInput input {
    padding: 8px 12px;
    border: 1px var(--border) solid;
    margin: 8px 0 8px 0;
    width: 100%;
    -webkit-border-radius: 0;
    border-radius: 0;
    transition: border-color .2s ease, color .2s ease, background-color .2s ease;
}

.paymentInput input:focus, .emailInput input:focus {
    border-color: var(--primary);
    outline: none!important;
}

.title {
    font-weight: 700;
    width: 100%;
}

.link {
    box-shadow: inset 0 -0.07em 0 var(--primary);
    font-weight: bold;
    color: inherit;
    transition: box-shadow 270ms cubic-bezier(0.77, 0, 0.175, 1), color 270ms cubic-bezier(0.77, 0, 0.175, 1);
}

.link:hover {
    box-shadow: inset 0 -0.85em 0 var(--primaryTransparent);
}

.container {
    width: 50%;
}
.itemContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 60px;
    margin-top: 60px;
}

@media (max-width: 1024px) {
    .itemContainer {
        grid-template-columns: 1fr 1fr;
    }
    .container {
        width: 63%;
    }
}

@media (max-width: 768px) {
    .itemContainer {
        grid-template-columns: 1fr;
    }
    .container {
        width: 100%;
        font-size: 14px;
    }
    .emailInput {
        width: 80%;
    }
}