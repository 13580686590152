html {
  scroll-behavior: smooth;
}

body {
  --text: #333333;
  --textSecondary: #708795;
  --textLow: #a5a5a5;
  --red: #E61010;
  --orange: #f2903bde;
  --green: #0AAE02;
  --backdrop: rgba(0, 0, 0, .14);
  --border-donate: #c2c2c2;
  --border: #eeeeee;
  --back: #ffffff;
  --backTransparent: rgba(255, 255, 255, 0);
  --primary: #9C8762;
  --primaryTransparent: rgba(156, 135, 98, 0.6);
  --secondary: #708795;
  background-color: var(--back);
  color: var(--text);
  font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body.dark {
  --text: #ffffff;
  --textSecondary: #a8a8a8;
  --red: #E61010;
  --backdrop: rgba(0, 0, 0, .8);
  --border: #4b4b4b;
  --back: #202020;
  --backTransparent: rgba(19, 19, 19, 0);
  --primary: #9C8762;
}

body.scrollDisabled {
  overflow: hidden;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Manrope", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.kz h1, .kz h2, .kz h3, .kz h4, .kz h5, .kz h6 {
  font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

#root {
  padding-top: 128px;
}

* {
  box-sizing: border-box;
}

.payments {
  padding-top: 32px;
}

.payments-cont{
  max-height: 600px;
  overflow-y: auto;
}

.payments .container {
  display: flex;
  justify-content: space-between;
}

.payments .container>div {
  width: calc(50% - 32px);
}

.payment {
  padding: 8px 0;
}

.payment:not(:last-of-type) {
  border-bottom: 1px #efefef solid;
}

.payment.in .payment-sum, .total-sum.positive {
  color: #0cc112;
}

.payment.out .payment-sum, .total-sum.negative {
  color: #c10c0c;
}

.payment-sum {
  font-weight: bold;
  font-size: 24px;
}

.payment-title {
  margin-top: 4px;
  color: #575757;
  font-weight: 600;
}

.date {
  font-size: 14px;
  font-weight: normal;
}

.slick-list, .slick-slider, .slick-track {
  position: relative;
  display: block
}

.slick-loading .slick-slide, .slick-loading .slick-track {
  visibility: hidden
}

.slick-slider {
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0
}

.slick-list:focus {
  outline: 0
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand
}

.slick-slider .slick-list, .slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0)
}

.slick-track {
  top: 0;
  left: 0
}

.slick-track:after, .slick-track:before {
  display: table;
  content: ''
}

.slick-track:after {
  clear: both
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px
}

[dir=rtl] .slick-slide {
  float: right
}

.slick-slide img {
  display: block
}

.slick-slide.slick-loading img {
  display: none
}

.slick-slide.dragging img {
  pointer-events: none
}

.slick-initialized .slick-slide {
  display: block
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent
}

.slick-arrow.slick-hidden {
  display: none
}

.float {
  display: flex;
  align-items: center;
  position: fixed;
  bottom: 24px;
  right: 24px;
  padding: 14px 18px;
  background-color: var(--back);
  border-radius: 28px;
  box-shadow: 0 8px 16px -4px var(--backdrop);
  font-weight: bold;
  text-transform: uppercase;
  font-size: 14px;
  opacity: 0;
}

.float.active {
  animation: fadeInBottom 2s 2s cubic-bezier(0.19, 1, 0.22, 1) both;
}

.float svg {
  margin-right: 12px;
  stroke: var(--red);
  stroke-dasharray: 54 10;
  stroke-dashoffset: 64;
  animation: draw 3s linear infinite forwards;
}

.subscribe{
  position: relative;
}

.subscribe::after{
  position: absolute;
  display: block;
  content: "";
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: var(--back);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='48' height='48' viewBox='0 0 24 24' fill='none' stroke='currentColor' strokeWidth='2' strokeLinecap='round' strokeLinejoin='round'%3E%3Cpolyline points='20 6 9 17 4 12'%3E%3C/polyline%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity .2s ease;
}

.subscribe.done::after{
  pointer-events: all;
  opacity: 1;
}

.subscribe p{
  font-size: 18px;
  text-align: center;
}

.subscribe button {
  border: none;
  display: block;
  cursor: pointer;
  outline: none!important;
  margin: auto;
  margin-top: 16px;
  appearance: none;
  color: #fff;
  background-color: var(--primary);
  padding: 12px 24px;
  text-transform: uppercase;
  font-size: 14px;
  text-decoration: none;
  box-shadow: 0 4px 16px -4px var(--border);
  transition: transform .3s ease-out, opacity .3s ease-out;
}

.subscribe button:hover {
  opacity: .9;
}

.subscribe button:active {
  transform: scale(0.98);
}

@keyframes draw {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeInBottom {
  from {
    transform: translateY(100px);
    opacity: 0;
  }
  to {
    transform: none;
    opacity: 1;
  }
}