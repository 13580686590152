.Input {
    position: relative;
}

.Input label {
    position: absolute;
    pointer-events: none;
    top: 18px;
    left: 8px;
    font-size: 16px;
    color: var(--textSecondary);
    transition: top .2s ease, font-size .2s ease;
}

.Input input, .Input textarea {
    display: block;
    width: 100%;
    appearance: none;
    border: none;
    border-bottom: 2px var(--border) solid;
    padding: 18px 8px 8px;
    font-size: 16px;
    appearance: none;
    color: var(--text);
    background-color: var(--back);
    -webkit-border-radius: none;
    border-radius: 0px;
    transition: border-color .2s ease;
}

.Input textarea {
    resize: vertical;
    min-height: 240px;
}

.Input input:focus+label, .Input input:not(:placeholder-shown)+label, .Input textarea:focus+label, .Input textarea:not(:placeholder-shown)+label {
    top: 4px;
    font-size: 11px;
}

.Input input:focus, .Input textarea:focus {
    outline: none!important;
    border-color: var(--primary);
}

.Input input:focus+label, .Input textarea:focus+label {
    color: var(--primary);
}