article {
    margin: 96px 0 96px 0;
}

.heading {
    font-size: 2em;
}

.authorContainer {
    display: flex;
    flex-direction: row;
    margin: 32px 0 32px 0;
    align-items: center;

}

.authorPic {
    border: 3px solid #fff;
    border-radius: 50%;
    width: 48px;
}

.details {
    margin: 0 0 0 16px;
}
.names {
    font-weight: 400;
    margin: 0 3px 0 3px;
    font-size: 1em;
}

.names:first-child {
    margin: 0 3px 0 0;
}

.stamp {
    /* border: 2px solid var(--text);
    background-color: var(--text);
    border-radius: 0.4rem;
    color: #fff;
    width: max-content;
    padding: 2px 8px 2px 8px; */
    background-color: var(--border);
    border-radius: 0.4rem;
    color: var(--textLow);
    padding: 12px 8px 12px 8px;
    width: 75%;
    text-align: center;
    margin: 0 0 16px 0;
}


.authors > img {
    margin-left: -16px;
}

.authors > img:first-child {
    margin-left: 0px;
}

.intro {
    padding: 8px 0px 8px 0px;
    font-size: 1.5em;
}

.paragraph {
    width: 75%;
    font-size: 1.2em;
}

.paragraph > a {
  color: var(--text);
  text-decoration: none;
  font-weight: 700;
  box-shadow: inset 0 -.07em 0 var(--primary);
  transition: box-shadow .27s cubic-bezier(.77,0,.175,1),color .27s cubic-bezier(.77,0,.175,1);
}

.paragraph > a:hover {
  border-color: var(--primary);
  box-shadow: inset 0 -0.85em 0 var(--primaryTransparent);
}

.back {
    color: var(--textLow);
    font-size: 14px;
    margin-bottom: 16px;
}

@media (max-width: 768px) {
    article {
        margin: 0 0 48px 0;
    }
    .heading {
        font-size: 1.5em;
    }
    .paragraph, .stamp {
        width: 100%
    }
    .stamp {
        font-size: 14px;
    }
    .intro {
        font-size: 1.2em;
    }
    .paragraph {
        font-size: 1em;
    }
    .authorContainer {
        margin: 24px 0 24px 0;
    }
}