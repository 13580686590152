.select{
    display: block;
    appearance: none;
    padding: 12px 8px;
    margin: 0 0 32px 0;
    max-width: 320px;
    width: 100%;
    border: none;
    border-bottom: 1px var(--border) solid;
    background-repeat: no-repeat;
    background-position: right center;
    outline: none!important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
}

.link {
    box-shadow: inset 0 -0.07em 0 var(--primary);
    font-weight: bold;
    color: inherit;
    transition: box-shadow 270ms cubic-bezier(0.77, 0, 0.175, 1), color 270ms cubic-bezier(0.77, 0, 0.175, 1);
}

.link:hover {
    box-shadow: inset 0 -0.85em 0 var(--primaryTransparent);
}

.filter {
    display: flex;
    gap: 1em;
}


.fundtitle {
    font-size: 24px;
    font-weight: 700;
}

.title {
    font-size: 24px;
    font-weight: 700;
    margin-top: 24px;
}

.fundGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;

}

.balEndowment {
    font-size: 48px;
    font-weight: 700;
    color: #03045E;
}

.balDev {
    font-size: 48px;
    font-weight: 700;
    color: #023E8A;
}

.balSalary {
    font-size: 48px;
    font-weight: 700;
    color: #0077B6;
}

.balSponsor {
    font-size: 48px;
    font-weight: 700;
    color: #0087BF;
}

.balTitle p {
    font-weight: 400;
    font-size: 16px;
    margin-top: 16px;
}
.balTitle a {
    font-weight: 400;
}
.endowInvest {
    font-size: 48px;
    font-weight: 700;
    color: #0096C7;
}

.endowCur {
    font-size: 48px;
    font-weight: 700;
    color: #00B4D8;
}

.balSalaryRob {
    font-size: 48px;
    font-weight: 700;
    color: #48CAE4;
}

.balDevRob {
    font-size: 48px;
    font-weight: 700;
    color: #90E0EF;
}

.updated {
    color: var(--textSecondary);
    margin-right: 10px;
}

.updated a {
    box-shadow: none !important;
    font-weight: 400;
    text-decoration: underline;
}

.updated a:hover {
    text-decoration: none;
}


@media (max-width: 768px) {
    .data {
        flex-direction: column;
    }
    .data>div {
        width: 100%;
    }
    .Donate p, .Donate ul {
        font-size: 16px;
    }
    .Donate h3 {
        font-size: 20px;
    }
    .fundGrid {
        grid-template-columns: 1fr;
    }
    .updated {
        display: block;
    }
    .hidden {
        display: none;
    }
    .filter {
        display: block;
    }
}