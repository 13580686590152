.Olympiads {
    font-size: 18px;
}

.Olympiads a {
    box-shadow: inset 0 -0.07em 0 var(--primary);
    font-weight: bold;
    color: inherit;
    transition: box-shadow 270ms cubic-bezier(0.77, 0, 0.175, 1), color 270ms cubic-bezier(0.77, 0, 0.175, 1);
}

.Olympiads a:hover {
    box-shadow: inset 0 -0.85em 0 var(--primaryTransparent);
}

.Olympiads p {
    font-size: 20px;
}

.timeline {
    padding: 32px 0;
    position: relative;
}

.timeline::after {
    position: absolute;
    top: 0;
    content: "";
    display: block;
    height: 100%;
    width: 1px;
    background: linear-gradient(to bottom, transparent 0%, var(--backdrop) 10%, var(--backdrop) 90%, transparent 100%);
    left: 50%;
}

.timelineBlock {
    position: relative;
    padding: 24px;
    background-color: var(--back);
    box-shadow: 0 8px 16px 0 var(--backdrop);
    border-radius: 13px;
    margin: 16px 0;
    width: calc(50% - 32px);
}

.timelineBlock::after {
    position: absolute;
    top: 50%;
    content: "";
    display: block;
    height: 1px;
    width: 32px;
    background-color: var(--backdrop);
    right: -32px;
}

.timelineBlock:nth-child(even) {
    margin-left: auto;
}

.timelineBlock:nth-child(even)::after {
    left: -32px;
    right: unset;
}

.timelineTitle {
    font-weight: bold;
    font-size: 20px;
}

.timelineDate {
    font-weight: 700;
    text-transform: uppercase;
    font-size: 13px;
    color: var(--textSecondary);
}

.pros {
    padding-top: 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.pro {
    width: calc(50% - 24px);
    margin: 12px 0;
}

.proTitle {
    font-size: 20px;
    font-weight: bold;
    margin: 12px 0;
}

p.proText {
    font-size: 16px;
}

@media (max-width: 768px) {
    .Olympiads p {
        font-size: 16px;
    }
    .timelineBlock {
        width: 100%;
        margin: 24px 0;
        box-shadow: 0 12px 32px -8px var(--backdrop);
    }
    .timelineBlock:nth-child(even) {
        margin-left: unset;
    }
    .timelineBlock::after {
        display: none;
    }
    .timeline::after {
        z-index: -1;
    }
    .pro {
        width: 100%;
    }
}