.Footer {
    background-color: #131313;
    color: #ffffff;
    padding: 96px 0;
}

.copyright {
    margin-top: 32px;
}

.socialLinks {
    display: flex;
}

.socialLinks a {
    color: inherit;
    text-decoration: none;
}

.socialLinks svg {
    display: block;
    height: 24px;
    width: 24px;
    fill: currentColor;
    transition: fill .2s ease-out;
}

.socialLinks a:not(:last-of-type) {
    margin-right: 16px;
}

.socialLinks a:hover svg {
    fill: var(--textSecondary);
}

.socialLinks a:active svg {
    fill: var(--primary);
}

.address {
    opacity: 0.7;
    font-size: 14px;
    margin: 8px 0;
}

.reg {
    opacity: 0.7;
    display: block;
    font-size: 14px;
    margin: 8px 0 8px 0;
    text-decoration: underline;
}

.reg:hover {
    text-decoration: none;
}

.policy {
    opacity: 0.7;
    display: block;
    font-size: 14px;
    margin: 8px 0 24px 0;
    text-decoration: underline;
}
.policy:hover {
    text-decoration: none;
}

@media (max-width: 768px) {
    .Logo {
        width: fit-content;
        margin: auto;
    }
    .Footer {
        padding: 48px 0 96px 0;
        display: flex;
        font-size: 14px;
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .socialLinks {
        justify-content: center;
    }
    .copyright {
        margin-top: 24px;
    }
}