.Communities {
    padding: 96px 0;
}

.Communities h2 {
    font-size: 32px;
    margin: 0 0 32px 0;
}

.item {
    display: flex;
    padding: 16px 0;
    width: 50%;
    align-items: center;
}

.item:nth-child(even) {
    margin-left: auto;
}

.itemIcon {
    width: 120px;
    margin-right: 16px;
    transition: transform .3s ease-out;
}

.item:hover .itemIcon {
    transform: scale(1.05);
}

.itemIcon img {
    max-width: 100%;
    display: block;
}

.itemInfo {
    width: calc(100% - 136px);
}

.itemInfoFirst {
    display: flex;
    align-items: center;
    margin-bottom: 4px;
}

.itemInfoFirst h3 {
    margin: 0 16px 0 0;
    font-weight: 800;
    font-size: 32px;
    line-height: 1;
}

.itemParticipants {
    display: inline-flex;
    border-radius: 16px;
    padding: 4px 8px;
    align-items: center;
    background-color: var(--red);
    color: #fff;
    font-size: 14px;
}

.itemParticipants svg {
    fill: #fff;
    height: 20px;
    width: 20px;
    margin-right: 4px;
}

.itemInfoSecond {
    color: var(--textSecondary);
    line-height: 1.1;
}

@media (max-width: 768px) {
    .Communities h2 {
        font-size: 24px;
    }
    .item {
        width: 100%;
        margin: unset;
    }
    .itemIcon {
        width: 96px;
    }
    .itemInfo {
        width: calc(100% - 112px);
    }
    .itemInfoFirst {
        justify-content: space-between;
        margin-bottom: 2px;
    }
    .itemInfoFirst h3 {
        font-size: 24px;
    }
    .itemInfoSecond {
        font-size: 14px;
        line-height: 1.1;
    }
}

@media (max-width: 420px) {
    .itemIcon {
        width: 72px;
        margin-right: 8px;
    }
    .itemInfoFirst {
        margin: 0;
    }
    .itemInfoFirst h3 {
        font-size: 20px;
        margin-right: 4px;
    }
    .itemParticipants {
        font-size: 13px;
        padding: 2px 6px;
    }
    .itemParticipants svg {
        width: 16px;
        height: 16px;
    }
    .itemInfo {
        width: calc(100% - 80px);
    }
    .itemInfoSecond {
        font-size: 13px;
        line-height: 1.1;
    }
}