.select{
    display: block;
    appearance: none;
    padding: 12px 8px;
    margin: -24px 0 32px 0;
    max-width: 320px;
    width: 100%;
    border: none;
    border-bottom: 1px var(--border) solid;
    background-repeat: no-repeat;
    background-position: right center;
    outline: none!important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23333' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath d='M6 9l6 6 6-6'/%3E%3C/svg%3E");
}
