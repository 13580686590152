.Index {
    padding-bottom: 32px;
}

.hero {
    padding-top: 48px;
    padding-bottom: 72px;
}

.caption {
    font-size: 10px;
    color: var(--textSecondary)
}

.hero .caption {
    position: absolute;
    bottom: -24px;
    right: 24px;
}

.logos{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 24px;
}

.logos>*{
    display: block;
}

.logos img{
    display: block;
    max-width: 100%;
}

.mediaLinks{
    display: flex;
    justify-content: center;
    align-items: center;
}

.mediaLinks>*{
    display: block;
    width: 25%;
}

.mediaLinks :not(:last-of-type){
    margin-right: 64px;
}

.mediaLinks img{
    display: block;
    max-width: 100%;
}

@keyframes fadeInBottom {
    from {
        transform: translateY(100px);
        opacity: 0;
    }
    to {
        transform: none;
        opacity: 1;
    }
}

@keyframes fadeInTop {
    from {
        transform: translateY(-100px);
        opacity: 0;
    }
    to {
        transform: none;
        opacity: 1;
    }
}

.heroContainer {
    position: relative;
}

.hero h1 {
    position: relative;
    text-transform: uppercase;
    font-size: 64px;
    max-width: 780px;
    text-align: center;
    line-height: 1.125;
    font-weight: 800;
    margin: auto;
    margin-bottom: -114px;
    animation: fadeInTop 1s 1s cubic-bezier(0.19, 1, 0.22, 1) both;
    z-index: 1;
}

.hero img {
    display: block;
    max-width: 100%;
    animation: fadeInBottom 1s 1s cubic-bezier(0.19, 1, 0.22, 1) both;
}

.hero .text {
    position: absolute;
    left: -24px;
    bottom: -72px;
    max-width: 480px;
    padding: 48px;
    background-color: var(--text);
    color: var(--back);
    font-size: 20px;
    animation: fadeInBottom 1s 1.6s cubic-bezier(0.19, 1, 0.22, 1) both;
}

.pun {
    padding: 96px 0;
}

.pun h2 {
    font-size: 20px;
    margin: 0 0 32px 0;
    color: var(--textSecondary)
}

.pun .text {
    margin: 0;
    font-family: "Manrope", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-weight: 800;
    text-transform: uppercase;
    max-width: 940px;
    font-size: 64px;
    line-height: 1.2;
}

:global(.kz) .pun .text {
    font-family: "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.pun .text .beyond {
    display: inline-block;
}

.pun .text .beyond div {
    transform-origin: left center;
    display: block;
    width: 100%;
    height: 8px;
    background-color: var(--red);
}

.how {
    background-color: var(--text);
    color: var(--back);
    padding: 96px 0;
    position: relative;
}

.how h2 {
    font-size: 48px;
    margin: 0 0 24px 0;
    line-height: 1.2;
    max-width: 608px;
}

.how p {
    font-size: 18px;
    max-width: 480px;
}

.how a {
    box-shadow: inset 0 -0.07em 0 var(--primary);
    font-weight: bold;
    color: inherit;
    transition: box-shadow 270ms cubic-bezier(0.77, 0, 0.175, 1), color 270ms cubic-bezier(0.77, 0, 0.175, 1);
}

.how a:hover {
    box-shadow: inset 0 -0.85em 0 var(--primaryTransparent);
}

.how .image {
    position: absolute;
    background-image: url("../Assets/how.jpg");
    background-position: center;
    background-size: cover;
    left: calc(50% + 96px);
    right: 0;
    height: 640px;
    top: 96px;
}

.how .image .caption {
    position: absolute;
    bottom: 0;
    padding: 8px;
    color: #ffffff;
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .5));
}

.postsWrap {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin: 0 -16px;
}

.post {
    width: calc(50% - 32px);
    margin: 16px;
}

.postImageWrapper {
    overflow: hidden;
}

.postImage {
    width: 100%;
    object-fit: cover;
    height: 200px;
    vertical-align: middle;
}

.postContent {
    padding: 16px 0;
    flex-direction: column;
    justify-content: space-between;
}

.postContent header {
    margin-bottom: 12px;
}

.postContent h2 {
    font-size: 24px;
    display: inline;
    line-height: 1.2;
    transition: box-shadow 270ms cubic-bezier(0.77, 0, 0.175, 1), color 270ms cubic-bezier(0.77, 0, 0.175, 1);
    box-shadow: inset 0 -0.07em 0 var(--primary);
    margin: 0;
}

.post:hover .postContent h2 {
    box-shadow: inset 0 -0.85em 0 var(--primaryTransparent);
}

.postContent footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.author {
    display: flex;
    align-items: center;
}

.authorPhoto {
    height: 32px;
    width: 32px;
    background-size: cover;
    border-radius: 50%;
    background-color: var(--textSecondary);
}

.authorName {
    width: calc(100% - 40px);
    margin-left: 8px;
    font-size: 13px;
    line-height: 1.1;
}

.postDate {
    font-size: 12px;
    color: var(--textSecondary);
}

.posts h2 {
    font-size: 32px;
    margin: 0 0 32px 0;
}

@media (max-width: 960px) {
    .hero .text {
        font-size: 16px;
        max-width: 360px;
    }
    .pun {
        padding: 64px 0;
    }
    .pun .text {
        font-size: 48px;
        max-width: 720px;
    }
    .pun .text .beyond div {
        height: 5px;
    }
    .how {
        padding: 64px 0 0 0;
    }
    .how h2, .how p {
        max-width: unset;
    }
    .how .image {
        position: unset;
        height: 320px;
        width: 100vw;
        margin: 0 -24px;
        background-position: bottom center;
        margin-top: 48px;
    }
}

@media (max-width: 900px){
    .logos{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 64px
    }
    .postImageWrapper {
        display: none;
    }
    .postContent h2 {
        font-size: 24px;
    }
}

@media (max-width: 768px) {
    .hero {
        padding-bottom: 48px;
    }
    .hero h1 {
        font-size: 48px;
        margin-bottom: -64px;
    }
    .hero .text {
        position: unset;
        max-width: 100%;
    }
    .pun .text {
        font-size: 32px;
    }
    .how h2, .posts h2 {
        font-size: 24px;
    }
    .how p {
        font-size: 16px;
    }
    .post {
        width: calc(100% - 32px);
        margin: 4px;
        padding: 0px 24px 0px 24px;
    }
    .postContent h2 {
        font-size: 20px;
    }
    .mediaLinks>*{
        width: 33.333%;
    }
    
    .mediaLinks :not(:last-of-type){
        margin-right: 48px;
    }
}

@media (max-width: 540px) {
    .hero h1 {
        font-size: 32px;
        margin-bottom: -32px;
    }
    .pun h2 {
        font-size: 18px;
        margin-bottom: 24px;
    }
    .logos{
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 48px
    }
    .mediaLinks>*{
        width: 50%;
    }

}

@media (max-width: 420px) {
    .hero h1 {
        font-size: 24px;
        margin-bottom: -24px;
    }
    .hero .text {
        padding: 24px;
    }
}