.Section {
    padding: 96px 0;
}

.Section h2 {
    font-size: 48px;
    font-weight: 800;
    margin: 0 0 32px 0;
}

.Section h2.big {
    font-size: 56px;
}

.Section:not(:first-of-type) {
    border-top: 1px var(--border) solid;
}

@media (max-width: 768px) {
    .Section {
        padding: 48px 0;
    }
    .Section h2 {
        font-size: 32px;
        margin-bottom: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .Section h2.big {
        font-size: 36px;
    }
}