.NotFound h2{
    margin: 0;
}

.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 128px);
    padding: 48px 0;
}

.error{
    font-size: 128px;
    font-weight: 800;
    font-family: "Manrope", "Inter", -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

@media (max-width: 768px){
    .NotFound h2{
        font-size: 20px;
    }
    .error{
        font-size: 72px;
    }
}